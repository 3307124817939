import React from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
    display flex;
    position: relative;
    align-items: center;

    @media (max-width: 426px) {
        flex-direction: column;
    }

    &:after {
        content: " ";
        display: block;
        background: #f0efe8;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        position: absolute;
        left: 0px;
        bottom: 0px;
    }
`

const Title = styled.h2`
    width: 100%;
    flex: .5;
    max-width: 50%;
    margin-top: 60px;

    heght: 100vh;
    justify-content: center;
    align-items: center;

    z-index: 2;

    @media (max-width: 426px) {
        flex: 1;
        max-width: 100%;
        display: flex;
        order: 1;
    }

    svg {
        width: 180px;
    }
`

const IndiceContent = styled.div`
    display: block;
    width: 100%;
    flex: .5;
    max-width: 50%;
    position: relative;
    z-index: 2;

    @media (max-width: 426px) {
        flex: 1;
        max-width: 100%;
        order: 2;
    }
`

const IndiceTitle = styled.h4`
    font-family: Qualey, sans-serif;
    font-size: 24px;
    margin-top: 60px;
    margin-bottom: 0px;
    margin-left: 60px;
`

const IndiceDivider = styled.div`
    height: 1px;
    width: 320px;
    background: #000;
    margin-bottom: 15px;
`

const IndiceLink = styled.div`
    display: block;
    margin-bottom: 8px;
    margin-left: 60px;

    a {
        font-family: 'Montserrat', sans-serif; 
        font-weight: 300;
        color: #000;
        text-decoration: none;
    }
`

const LinkList = styled.div`
    display: block;
    margin-bottom: 30px;
`

const Indice = () => {
    return (
        <Wrapper id="indice">
            <IndiceContent>
                <IndiceTitle>COLEÇÕES</IndiceTitle>
                <IndiceDivider />
                <LinkList>
                    <IndiceLink><Link to="/colecao-geometricos">COLEÇÃO GEOMÉTRICOS</Link></IndiceLink>
                    <IndiceLink><Link to="/colecao-acquarel">COLEÇÃO ACQUARELL</Link></IndiceLink>
                    <IndiceLink><Link to="/colecao-blossom">COLEÇÃO BLOSSOM</Link></IndiceLink>
                    <IndiceLink><Link to="/colecao-tropical">COLEÇÃO TROPICAL</Link></IndiceLink>
                    <IndiceLink><Link to="/colecao-into-the-woods">COLEÇÃO INTO THE WOODS</Link></IndiceLink>
                    <IndiceLink><Link to="/colecao-birds">COLEÇÃO BIRDS</Link></IndiceLink>
                    <IndiceLink><Link to="/colecao-sea">COLEÇÃO SEA</Link></IndiceLink>
                    <IndiceLink><Link to="/colecao-nature">COLEÇÃO NATURE</Link></IndiceLink>
                    <IndiceLink><Link to="/colecao-espiritual">COLEÇÃO SPIRITUALITAS</Link></IndiceLink>
                </LinkList>

                <IndiceTitle>CLASSICOS</IndiceTitle>
                <IndiceDivider />
                <LinkList>
                    <IndiceLink><Link to="/colecao-rosas">ROSAS</Link></IndiceLink>
                    <IndiceLink><Link to="/colecao-borboletas">BORBOLETAS</Link></IndiceLink>
                    <IndiceLink><Link to="/colecao-dalias">DÁLIAS</Link></IndiceLink>
                    <IndiceLink><Link to="/colecao-alcachofras">ALCACHOFRAS</Link></IndiceLink>
                </LinkList>

                <IndiceTitle>BASICOS</IndiceTitle>
                <IndiceDivider />
                <LinkList>
                    <IndiceLink><Link to="/colecao-lacados">LACADOS</Link></IndiceLink>
                    <IndiceLink><Link to="/colecao-rusticos">RÚSTICOS</Link></IndiceLink>
                    <IndiceLink><Link to="/colecao-basicos">BÁSICOS</Link></IndiceLink>
                </LinkList>
            </IndiceContent>
            <Title>
                <svg x="0px" y="0px"viewBox="0 0 175.5 207.5">
                <g>
                    <path d="M2.2,5.7H0L6.2,0l1.9,0.9L2.2,5.7z M0.9,42.6V10.3h1.9v32.3H0.9z"/>
                </g>
                <g>
                    <path d="M53.8,85.6L32.3,56.2v29.3h-1.9V53.3h2.3l21.6,29.3V53.3h1.9v32.3H53.8z"/>
                </g>
                <g>
                    <path d="M104.7,77c9.2,0,16.6,7.1,16.6,16.2c0,9-7.4,16.1-16.6,16.1H91.8V77H104.7z M93.8,107.4h10.9c8.1,0,14.6-6.3,14.6-14.2
                        c0-7.9-6.5-14.3-14.6-14.3H93.8V107.4z"/>
                </g>
                <g>
                    <path d="M26.4,185.6v-32.3h1.9v32.3H26.4z"/>
                </g>
                <g>
                    <path d="M102.3,179.4l-1.2,1.5c-2.7-2.5-6.3-4-10.2-4c-8.2,0-14.8,6.4-14.8,14.3c0,7.9,6.6,14.4,14.8,14.4c3.8,0,7.4-1.5,10.2-3.9
                        l1.2,1.3c-3,2.8-7.1,4.5-11.4,4.5c-9.2,0-16.7-7.2-16.7-16.2c0-9,7.5-16.2,16.7-16.2C95.3,175.1,99.3,176.8,102.3,179.4z"/>
                </g>
                <g>
                    <path d="M156,155.2v12.9h16.8v1.9H156v13.7h19.5v1.9h-21.4v-32.3h20.8v1.9H156z"/>
                </g>
                </svg>
            </Title>
        </Wrapper>
    );
}

export default Indice;