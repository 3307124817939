import React, { useState, useEffect } from 'react';

export default Image = (props) => {
    const [image, setImage] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.imageLoader.getImage(props.src, (val) => {
            var reader = new FileReader();
            reader.readAsDataURL(val); 
            reader.onloadend = function() {
                setImage(reader.result);     
                setLoading(false);
            }
        })
    }, ['image']);

    return (
        <div>
            {
                !loading &&
                <img src={image} alt={props.alt} />
            }
            {
                loading &&
                <span></span>
            }
        </div>
    )
}