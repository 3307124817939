import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Nav = styled.div`
    position: absolute;
    right: 0px;
    top: 15px;
    display: flex;
    z-index: 10000;

    a {
        color: #000;
        font-size: 14px;
        display: block;
        margin-right 15px;
        text-decoration: none;
        text-transform: uppercase;
    }
`


const Menu = () => {
    return (
    <Nav>
        <Link to="/"> Home </Link>
        <Link to="/indice"> Índice </Link>
    </Nav>
    )
}

export default Menu;