import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'
import Image from '../../Image';

const PageTitle = styled.div`
    font-family: Qualey, sans-serif;
    font-size: 44px;
    margin-top: 15px;
    margin-bottom: 30px;
    margin-left: 0px;
`

const PageContent = styled.div`
    display flex;
    position: relative;
    height: 100%;
    min-height: 100vh;

    &:after {
        content: " ";
        display: block;
        background: #e0e9e7;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        position: absolute;
        right: 0px;
        bottom: 0px;
    }
`

const Content = styled.div`
    position: relative;
    z-index: 2;
    width: 370px;
    padding-left: 80px;
    padding-right: 20px;

    a {
        padding: 15px 51px;
        font-family: "Qualey", sans-serif;
        font-size: 14px;
        letter-spacing: 2px;
        color: #000;
        text-transform: uppercase;
        border-radius: 0px;
        transition: all 0.4s ease-out;
        border: 2px solid #000;
        background-color: transparent;
        text-decoration: none;
    }
`

const Media = styled.div`
    flex: 1;
    margin-top: 140px;
    position: relative;
    z-index: 2;

    img {
        width: 100%;
    }
`

const Text = styled.div`
    font-size: 12px;
    color: #000;
    font-family: 'Montserrat', sans-serif; 
    font-weight: 300;
    margin-bottom: 50px;
`

const Geometricos = () => {
    return(
        <div className="page">
            <PageContent>
                <Content>
                    <PageTitle>COLEÇÃO <br /> &nbsp;&nbsp;&nbsp;GEOMÉTRICOS</PageTitle>
                    <Text>
                        <p>
                            Do grego geo, que significa “terra”, e metria, que significa
                            “medida” assim se forma a palavra geometria.
                        </p>
                        <p>
                            Pontos, superfície, volume e linhas bem marcadas, a contrastar
                            com a palete de cores suaves. <b>A coleção Geométricos
                            foi pensada para dar o toque de classe cheio de personalidade
                            que procurava para aquele recanto da sua sala.</b>
                        </p>
                    </Text>
                    <Link to="/produtos/co/geometricos">Produtos</Link>
                </Content>
                <Media>
                    <Image src="/images/geometricos-img.jpg" alt="geometricos" /> 
                </Media>
            </PageContent>
        </div>
    );
}

export default Geometricos;