import React from 'react';
import Image from '../Image';

import styled from 'styled-components';

const Row = styled.div`
    margin-top: 120px;
    display: flex;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    position: relative;
    padding-left: 60px;

    &:after {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 94%;
        background-color: #f1f2ec;
        content: " ";
        display: block;
    }
`

const Capa = styled.div`
    width: 100%;
    max-width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 112px;
    position: relative;
    z-index: 4;

    img {
        width: 80%;
    }
`

const Content = styled.div`
    width: 100%;
    max-width: 42%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;
    margin-left: -30px;
    position: relative;
    z-index: 4;
`

const Title = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    svg {
        width: 40%;
        margin-left: 40px;
    }
`

const Details = styled.div`
    width: 74%;
    margin-top: 40px;
    font-family: Montserrat, sans-serif;

    p {
        font-size: 11px;
    }

    h4 {
        font-weight: bold;
        font-size: 11px;
    }

    p.muted {
        color: #929696;
        font-size: 11px;
    }
`

const Home = () => {
    return (
        <div className="page with-bg">
            <Row>
                <Capa>
                    <Image src={'/images/capa_sobre.jpg'}/>
                </Capa>
                <Content>
                    <Title>
                    <div>
                        <svg version="1.1" viewBox="0 0 181.8 229.2"
                            x="0px" y="0px" >
                        <path style={{ fill:'#ABADAD' }} d="M15.8,2l-0.6,1.3C13.4,2.1,11,1.5,9,1.5c-3.8,0-6.2,1.6-6.2,4.1c0,7.4,13.9,3.6,13.8,12.5
                            c0,3.7-3.3,6.1-8.2,6.1c-3.1,0-6.4-1.4-8.4-3.5l0.7-1.3c1.9,2,4.9,3.3,7.7,3.3c4.1,0,6.7-1.8,6.7-4.6c0.1-7.6-13.8-3.7-13.8-12.4
                            C1.3,2.2,4.4,0,9.1,0C11.5,0,14.1,0.8,15.8,2"/>
                        <rect x="38.9" y="31.7" style={{ fill:'#ABADAD' }} width="1.4" height="24.2"/>
                        <polygon style={{ fill:'#ABADAD' }} points="92.8,48.4 77.1,26.5 77.1,48.4 75.6,48.4 75.6,24.3 77.3,24.3 93.1,46.2 93.1,24.3 94.5,24.3 
                            94.5,48.4 "/>
                        <path style={{ fill:'#ABADAD' }} d="M133,79.7l-0.8,1.1c-2-1.9-4.6-3-7.4-3c-6,0-10.8,4.8-10.8,10.7c0,5.9,4.8,10.7,10.8,10.7
                            c2.8,0,5.4-1.1,7.4-2.9l0.9,1c-2.2,2.1-5.2,3.4-8.4,3.4c-6.7,0-12.2-5.4-12.2-12.1c0-6.7,5.5-12.1,12.2-12.1
                            C127.9,76.4,130.8,77.7,133,79.7"/>
                        <polygon style={{ fill:'#ABADAD' }} points="167.6,49.8 167.6,59.5 179.9,59.5 179.9,60.9 167.6,60.9 167.6,71.1 181.8,71.1 181.8,72.6 166.2,72.6 
                            166.2,48.4 181.4,48.4 181.4,49.8 "/>
                        <polygon points="40.6,165.6 40.6,144 35.8,144 35.8,141.4 43.3,141.4 43.3,165.6 "/>
                        <path d="M72.9,185.8l1.1-2.2c1.6,0.9,3.6,1.5,5.4,1.5c5.1,0,7.6-4.2,7.2-10.8c-1,2.8-3.6,4.4-7.1,4.4c-4.6,0-7.5-2.9-7.5-7.6
                            c0-4.9,3.1-8,8-8c5.7,0,9.2,4.3,9.2,11.7c0,7.7-3.7,12.7-9.7,12.7C77.4,187.6,74.8,186.9,72.9,185.8 M86,171c0-2.3-1.7-5.3-5.8-5.3
                            c-3.3,0-5.6,2-5.6,5.5c0,3.2,2.1,5.2,5.4,5.2C83.8,176.4,86,173.8,86,171"/>
                        <path d="M132,162.4l-1.1,2.2c-1.6-0.9-3.6-1.5-5.4-1.5c-5.1,0-7.6,4.2-7.2,10.8c1-2.8,3.6-4.4,7.1-4.4c4.6,0,7.5,2.9,7.5,7.6
                            c0,4.9-3.1,8-8,8c-5.7,0-9.2-4.2-9.2-11.7c0-7.7,3.7-12.7,9.7-12.7C127.5,160.7,130.1,161.4,132,162.4 M118.9,177.3
                            c0,2.3,1.7,5.3,5.7,5.3c3.3,0,5.6-2.1,5.6-5.5c0-3.2-2.1-5.2-5.4-5.2C121.1,171.8,118.9,174.4,118.9,177.3"/>
                        <path d="M168.1,210.9c0,2.5-1.5,4.3-3.9,5.3c3.1,0.9,5,3.1,5,6.1c0,4.3-3.3,6.9-8.7,6.9c-5.4,0-8.8-2.6-8.8-6.8c0-3.1,2-5.3,5.1-6.2
                            c-2.5-1-4-2.9-4-5.4c0-3.8,2.9-6,7.6-6C165.2,204.8,168.1,207.1,168.1,210.9 M154.4,222.1c0,3,2.3,4.8,6.2,4.8
                            c3.8,0,6.2-1.8,6.2-4.8c0-2.9-2.3-4.6-6.2-4.6C156.7,217.5,154.4,219.2,154.4,222.1 M155.3,211c0,2.6,2,4.1,5.3,4.1
                            c3.3,0,5.2-1.5,5.2-4.1c0-2.6-2-4.1-5.2-4.1C157.3,206.9,155.3,208.4,155.3,211"/>
                        </svg>
                        <Details>
                            <p>
                                Quando em 1968, Manuel Pedro Custódio pedalou pela
                                primeira vez rumo ao Santuário de Fátima para vender as
                                velas religiosas que ele próprio produzia, não imaginava
                                que aquela não era apenas uma viagem de trabalho, mas
                                a viagem da sua vida.
                            </p>
                            <h4>Nasceu assim a Manulena.</h4>  
                            <p className="muted">
                                Hoje, decorridos mais de 50 anos, a Manulena mantém-se
                                fiel às suas origens, enquanto, já pela mão de uma segunda
                                geração, percorre o caminho da inovação e da constante
                                busca pela excelência.
                            </p>
                        </Details>
                    </div>
                    </Title>

                </Content>
            </Row>
        </div>
    )
}

export default Home;