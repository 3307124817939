import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Image from '../Image';

const Item = styled.div`
    display:block;
    img {
        width: 100%;
    }

    a {
        text-decoration: none;
    }
`

const ProductTitle = styled.h3`
    font-size: 18px;
    color: #000;
    margin-bottom: 5px;
    font-weight: normal;
    color: #231f20;
    margin-top: 10px;
    font-family: Montserrat, sans-serif;

`

const ProductRef = styled.h6`
    font-weight: normal;
    font-size: 13px;
    margin-top: 0px;
    color: #231f20;
    font-family: Montserrat, sans-serif;

`

const ProductItem = ({product}) => {
    return (
        <Item>
            <Link to={"/produtos/" + product.id}>
                <Image src={'/' + product.images[0].path} /> 
                <ProductTitle>
                    {product.title}
                </ProductTitle>
                <ProductRef>
                    {product.ref}
                </ProductRef>
            </Link>
        </Item>
    )
}

export default ProductItem;
