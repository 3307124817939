import localstorage from 'localforage';

export default class ImageLoader {
    constructor() {
        this.images = [];
    }

    async store(imageUrl) {
        try {
            let blob = await this.loadImageAsBlob(imageUrl);
            this.images.push({src: imageUrl, blob: blob})
            localstorage.setItem(imageUrl, blob); 
        } catch (err) {
            console.error(err);
        }   
    }

    loadImageAsBlob(imageUrl) {
        return fetch(imageUrl)
            .then(response => {
                if (response.status == 200) {
                    return response;
                }

                throw new Error('not found');
            })
            .then(response => {
                return response.blob();
            })
    }

    getImage(imageUrl, callback) {
        localstorage.getItem(imageUrl).then(value => {
            if (value) {
                callback(value)
            }

            let img = this.images.find(i => i.src == imageUrl);
            return callback(img.blob);
        });
    }
}

