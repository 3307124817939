import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; 
import { find } from '../db/Product';
import Image from '../Image';

const findValue = (value_id, attribute) => {
    return attribute.values.find(v => v.id == value_id ).type_value
}

const ProductRow = styled.div`
    display: flex;
    width: 100%;
    margin-top: 120px;
`

const Media = styled.div`
    max-width: 55%;
    width: 100%;

    img {
        width: 100%;
    }
` 

const ProductDetails =  styled.div`
    width: 45%;
    display: flex;
    justify-content: center;
`


const Details =  styled.div`
    width: 425px;
`

const Name = styled.h2`
    margin-bottom: 8px;
    font-family: "Qualey", sans-serif;
    font-size: 22px;
    text-transform: uppercase;
`

const Category = styled.h4`
    color: #ccc;
    margin-bottom: 48px;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
`

const ProductDetail = styled.div`
    margin-bottom: 22px;
`

const ProductDetailTitle = styled.h5`
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 2px;
    font-family: "Montserrat", sans-serif;
    color: #000000;
`

const ProductDetailText = styled.div`
    font-size: 12px;
    letter-spacing: 2px;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    font-weight: 300;
`

const Colors = styled.div`
    margin-top: 5px;
`

const ColorsGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const Color = styled.div`
    margin-right: 8px;
    margin-bottom: 8px;

    
    a {
        height: 38px;
        width: 38px;
        display: block;
        border-radius: 50%;
    }

    ${props => props.selected ? `
        a {
            border: 2px solid #000;
        }
    ` : '' }
`


const Produto = () => {
    let { id } = useParams();
    let [ loading, setLoading ] = useState(true);
    let [ product, setProduct ] = useState(null);
    let [ productId, setProductId ] = useState(id);

    useEffect(() => {
        console.log('new', id);
        setProduct(null);
        setProduct(find(id));
        setProductId(id);
        setLoading(false);
    }, [id])

    return (
        <div className="page">
            {
                !loading &&
                <ProductRow>
                    <Media>
                        <Image src={'/' + product.images[0].path} key={productId} /> 
                    </Media>
                    <ProductDetails>
                        <Details>
                            <Name>{product.title}</Name>
                            <Category>{product.category.title}</Category>
                            <ProductDetail>
                                <ProductDetailTitle>Descrição:</ProductDetailTitle>
                                <ProductDetailText dangerouslySetInnerHTML={{ __html: product.description }}></ProductDetailText>
                            </ProductDetail>
                            {
                                product.dimensoes && product.dimensoes != '' &&
                                <ProductDetail>
                                    <ProductDetailTitle>Dimensões:</ProductDetailTitle>
                                    <ProductDetailText dangerouslySetInnerHTML={{ __html: product.dimensoes }}></ProductDetailText>
                                </ProductDetail>
                            }
                            {
                                product.quantidade && product.quantidade != '' &&
                                <ProductDetail>
                                    <ProductDetailTitle>Quantidade:</ProductDetailTitle>
                                    <ProductDetailText dangerouslySetInnerHTML={{ __html: product.quantidade }}></ProductDetailText>
                                </ProductDetail>
                            }
                            <ProductDetail>
                                <ProductDetailTitle>REF:</ProductDetailTitle>
                                <ProductDetailText dangerouslySetInnerHTML={{ __html: product.ref }}></ProductDetailText>
                            </ProductDetail>

                            <ProductDetail>
                                <ProductDetailTitle>CORES DISPONÍVEIS:</ProductDetailTitle>
                                <Colors>
                                    {
                                        product.groups.map(group => {
                                            return (
                                                <div>
                                                    {
                                                        group.attribute.type_attribute == 'color' &&
                                                        <ColorsGroup>
                                                            {
                                                                group.members.map(member => {
                                                                    return <Color>
                                                                            <Link
                                                                                to={ '/produtos/' + member.produto.id }
                                                                                style={{ backgroundColor: findValue(member.group_value_id, member.attribute)}}
                                                                            ></Link>
                                                                        </Color>
                                                                })
                                                            }
                                                        </ColorsGroup>   
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </Colors>
                            </ProductDetail>
                        </Details>
                    </ProductDetails>
                </ProductRow>
            }
            {
                loading &&
                <div>loading</div> 
            }
        </div>
    )
}

export default Produto;