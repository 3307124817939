import collectionsJson from './collections.json';
import productsJson from './products.json';

const find = (slug) => {
    return withProducts(collectionsJson.find(p => p.slug == slug));
}

const withProducts = (collection) => {
    if (!collection) {
        return null;
    }

    collection.products = productsJson.filter(p => p.colecao_id == collection.id);

    return collection;
}

export {
    find
}