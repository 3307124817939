import React, { useState, useEffect } from 'react';
import {
    useParams
} from "react-router-dom";
import { find } from '../../db/Collection';
import styled from 'styled-components'
import ProductItem from '../../components/ProductItem';

const PageTitle = styled.div`
    font-family: Qualey, sans-serif;
    font-size: 44px;
    margin-top: 15px;
    margin-bottom: 30px;
    margin-left: 0px;
`

const PageContent = styled.div`
    display flex;
    position: relative;
    height: 100%;
    min-height: 100vh;

    &:after {
        content: " ";
        display: block;
        background: #e0e9e7;
        width: 100px;
        height: 100%;
        position: absolute;
        left: 0px;
        bottom: 0px;
    }
`

const Content = styled.div`
    position: relative;
    z-index: 2;
    width: 100%;
    padding-left: 80px;
    padding-right: 80px;
`

const ProductList = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    padding-left: 120px;
`


const Collection = () => {
    let { colSlug } = useParams();
    let [ loading, setLoading ] = useState(true);
    let [ collection, setCollection ] = useState(null);

    useEffect(() => {
        setCollection(find(colSlug));
        setLoading(false);
    }, ['collection'])

    return (
        <div className="page">
            {
                !loading && 
                <PageContent>
                    <Content>
                        <PageTitle>
                            Coleção {collection.title}
                        </PageTitle>
                        <ProductList>
                            {
                                collection.products.map((product) => {
                                    return (
                                        <ProductItem product={product} key={'product-item-' + product.id}></ProductItem>
                                    )
                                })
                            }
                        </ProductList>
                    </Content>
                </PageContent>
            }
            {
                loading &&
                <h2>Loading</h2>
            }
        </div>
    )
}

export default Collection;