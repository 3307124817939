import React from 'react';
import Image from '../Image';

import styled from 'styled-components';

const Row = styled.div`
    display: flex;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    position: relative;
    padding-left: 60px;

    &:after {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 94%;
        background-color: #f1f2ec;
        content: " ";
        display: block;
    }
`

const Capa = styled.div`
    width: 100%;
    max-width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
    z-index: 4;

    img {
        width: 80%;
    }
`

const Title = styled.div`
    width: 100%;
    max-width: 35%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;
    position: relative;
    z-index: 4;

    svg {
        width: 40%;
    }
`


const Home = () => {
    return (
        <div className="page with-bg home">
            <Row>
                <Capa>
                    <Image src={'/images/capa.jpg'}/>
                </Capa>
                <Title>
                    <svg version="1.1"
                        x="0px" y="0px" viewBox="0 0 158.3 478.8">
                    <g>
                        <g>
                            <path d="M28,4.3l-1.2,1.5c-2.7-2.5-6.3-4-10.2-4C8.5,1.8,1.9,8.2,1.9,16.1c0,7.9,6.6,14.4,14.8,14.4c3.8,0,7.4-1.5,10.2-3.9
                                l1.2,1.3c-3,2.8-7.1,4.5-11.4,4.5C7.5,32.4,0,25.1,0,16.2S7.5,0,16.7,0C21,0,25,1.7,28,4.3z"/>
                        </g>
                        <g>
                            <path d="M37.5,67.4l-4.2,9.2h-2.1l14.6-32.3h2.1l14.8,32.3h-2.1l-4.2-9.2H37.5z M46.9,46.5l-8.5,18.9h17.2L46.9,46.5z"/>
                        </g>
                        <g>
                            <path d="M94,32.5v30.4H92V32.5H81.1v-1.9h23.7v1.9H94z"/>
                        </g>
                        <g>
                            <path d="M123.7,86.6l-4.2,9.2h-2.1l14.6-32.3h2.1l14.8,32.3h-2.1l-4.2-9.2H123.7z M133.1,65.7l-8.5,18.9h17.2L133.1,65.7z
                                M131.3,58.9l6.2-5.7l1.9,0.9l-5.9,4.8H131.3z"/>
                        </g>
                        <g>
                            <path d="M29,135v1.9H12.7v-32.3h1.9V135H29z"/>
                        </g>
                        <g>
                            <path d="M103.3,139.8c0,9-7.5,16.2-16.7,16.2s-16.7-7.2-16.7-16.2s7.5-16.2,16.7-16.2S103.3,130.9,103.3,139.8z M71.9,139.8
                                c0,7.9,6.6,14.3,14.7,14.3s14.7-6.4,14.7-14.3s-6.6-14.3-14.7-14.3S71.9,131.9,71.9,139.8z"/>
                        </g>
                        <g>
                            <path d="M70.6,199.7v11.7c-3,2.6-7.2,4.2-11.5,4.2c-9.2,0-16.7-7.2-16.7-16.2s7.5-16.2,16.8-16.2c4.3,0,8.4,1.6,11.4,4.2l-1.2,1.4
                                c-2.7-2.4-6.3-3.8-10.2-3.8c-8.2,0-14.8,6.4-14.8,14.3c0,7.9,6.6,14.4,14.8,14.4c3.6,0,7-1.2,9.6-3.3v-10.7H70.6z"/>
                        </g>
                        <g>
                            <path d="M157.4,216.8c0,9-7.5,16.2-16.7,16.2c-9.2,0-16.7-7.2-16.7-16.2s7.5-16.2,16.7-16.2C149.9,200.6,157.4,207.8,157.4,216.8z
                                M125.9,216.8c0,7.9,6.6,14.3,14.7,14.3s14.7-6.4,14.7-14.3c0-7.9-6.6-14.3-14.7-14.3S125.9,208.9,125.9,216.8z"/>
                        </g>
                        <g>
                            <path style={{ fill:'#B38F55' }} d="M27.7,280.7c0,2.8-2,6.4-5.4,10l-10.9,12h18.4v1.8H9.1v-1.9L21,289.5c3-3,4.8-6.3,4.8-8.6
                                c0-3.6-2.8-5.8-7.3-5.8c-2.9,0-6.2,1.4-8.5,3.8l-0.9-1.5c2.6-2.6,6.2-4.1,9.6-4.1C24.2,273.3,27.7,276.1,27.7,280.7z"/>
                        </g>
                        <g>
                            <path style={{ fill:'#B38F55' }} d="M78.3,315.9c0,10-4.5,15.6-12.5,15.6c-8,0-12.5-5.6-12.5-15.6s4.5-15.6,12.5-15.6
                                C73.8,300.3,78.3,305.9,78.3,315.9z M55.1,315.9c0,9,3.7,13.9,10.6,13.9c6.9,0,10.6-4.9,10.6-13.9c0-9.1-3.7-13.9-10.6-13.9
                                C58.9,302,55.1,306.8,55.1,315.9z"/>
                        </g>
                        <g>
                            <path style={{ fill:'#B38F55' }} d="M113.3,321.4v-29.2h-6.5v-1.8h8.4v31.1H113.3z"/>
                        </g>
                        <g>
                            <path style={{ fill:'#B38F55' }} d="M137.8,354.4l0.8-1.6c2.2,1.2,5,1.9,7.4,1.9c7.4,0,11.2-6.6,10.4-17.1c-1.1,4.6-4.8,7.4-10,7.4
                                c-6,0-9.7-3.7-9.7-9.7c0-6.2,4-10,10-10c7.3,0,11.7,5.2,11.7,14.8c0,9.9-4.7,16.4-12.2,16.4C143.5,356.5,140.3,355.7,137.8,354.4z
                                M155.6,335.1c0-3.3-2.6-8-8.7-8c-5,0-8.5,3.2-8.5,8.3c0,4.9,3.2,7.9,8.1,7.9C152.2,343.3,155.6,339.3,155.6,335.1z"/>
                        </g>
                        <g>
                            <path style={{ fill: '#ABADAD' }} d="M24.3,408.8c0,2.8-2,6.4-5.4,10L8,430.7h18.4v1.8H5.8v-1.9l11.9-13.1c3-3,4.8-6.3,4.8-8.6
                                c0-3.6-2.8-5.8-7.3-5.8c-2.9,0-6.2,1.4-8.5,3.8l-0.9-1.5c2.6-2.6,6.2-4.1,9.6-4.1C20.8,401.5,24.3,404.3,24.3,408.8z"/>
                        </g>
                        <g>
                            <path style={{ fill: '#ABADAD' }} d="M76.3,435.7c0,10-4.5,15.6-12.5,15.6c-8,0-12.5-5.6-12.5-15.6s4.5-15.6,12.5-15.6
                                C71.8,420.2,76.3,425.7,76.3,435.7z M53.1,435.7c0,9,3.7,13.9,10.6,13.9c6.9,0,10.6-4.9,10.6-13.9c0-9.1-3.7-13.9-10.6-13.9
                                C56.9,421.9,53.1,426.7,53.1,435.7z"/>
                        </g>
                        <g>
                            <path style={{ fill: '#ABADAD' }} d="M116.3,408.8c0,2.8-2,6.4-5.4,10l-10.9,12h18.4v1.8H97.8v-1.9l11.9-13.1c3-3,4.8-6.3,4.8-8.6
                                c0-3.6-2.8-5.8-7.3-5.8c-2.9,0-6.2,1.4-8.5,3.8l-0.9-1.5c2.6-2.6,6.2-4.1,9.6-4.1C112.9,401.5,116.3,404.3,116.3,408.8z"/>
                        </g>
                        <g>
                            <path style={{ fill: '#ABADAD' }} d="M154.3,463.2c0,10-4.5,15.6-12.5,15.6c-8,0-12.5-5.6-12.5-15.6s4.5-15.6,12.5-15.6
                                C149.8,447.7,154.3,453.2,154.3,463.2z M131.1,463.2c0,9,3.7,13.9,10.6,13.9c6.9,0,10.6-4.9,10.6-13.9c0-9.1-3.7-13.9-10.6-13.9
                                C134.9,449.4,131.1,454.2,131.1,463.2z"/>
                        </g>
                    </g>
                    </svg>
                </Title>
            </Row>
        </div>
    )
}

export default Home;