import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ImageLoader from './ImageLoader';
import ProductJson from './db/products.json'

const loadImages = () => {
    window.imageLoader = new ImageLoader();
    window.imageLoader.store('/images/acquarel-img.jpg');
    window.imageLoader.store('/images/alcachofras-img.jpg');
    window.imageLoader.store('/images/basicos-img.jpg');
    window.imageLoader.store('/images/birds-img.jpg');
    window.imageLoader.store('/images/blossom-img.jpg');
    window.imageLoader.store('/images/borboletas-img.jpg');
    window.imageLoader.store('/images/dalias-img.jpg');
    window.imageLoader.store('/images/espiritual-img.jpg');
    window.imageLoader.store('/images/geometricos-img.jpg');
    window.imageLoader.store('/images/tropical-img.jpg');
    window.imageLoader.store('/images/into-the-woods-img.jpg');
    window.imageLoader.store('/images/lacados-img.jpg');
    window.imageLoader.store('/images/nature-img.jpg');
    window.imageLoader.store('/images/rosas-img.jpg');
    window.imageLoader.store('/images/rusticos-img.jpg');
    window.imageLoader.store('/images/sea-img.jpg');
    window.imageLoader.store('/images/history.jpg');
    window.imageLoader.store('/images/home.jpg');
    window.imageLoader.store('/images/capa.jpg');
    window.imageLoader.store('/images/capa_sobre.jpg');
    
    ProductJson.map((product) => {
        window.imageLoader.store('/' + product.images[0].path);
    })
}
(async () => {
    await loadImages();
})()

setTimeout(() => {
    ReactDOM.render(<App />, document.getElementById('root'));
}, 1000)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
