import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './page/Home';
import History from './page/History'
import Indice from './page/Indice'
import Geometricos from './page/colecoes/Geometricos';
import Acquarel from './page/colecoes/Acquarel';
import Blossom from './page/colecoes/Blossom';
import Tropical from './page/colecoes/Tropical';
import IntoTheWoods from './page/colecoes/IntoTheWoods';
import Birds from './page/colecoes/Birds';
import Sea from './page/colecoes/Sea';
import Nature from './page/colecoes/Nature';
import Espiritual from './page/colecoes/Spiritualitas';
import Rosas from './page/colecoes/Rosas';
import Borboletas from './page/colecoes/Borboletas';
import Dalias from './page/colecoes/Dalias';
import Alcachofras from './page/colecoes/Alcachofras';
import Lacados from './page/colecoes/Lacados';
import Rusticos from './page/colecoes/Rusticos';
import Basicos from './page/colecoes/Basicos';
import Collection from './page/colecoes/Index';
import Produto from './page/Produto';
import Menu from './Menu';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";



function App() {
  return (
    <Router>
      <Menu />
      <Switch>
        <Route path="/colecao-geometricos" exact>
          <Geometricos />
        </Route>
        <Route path="/colecao-acquarel" exact>
          <Acquarel />
        </Route>
        <Route path="/colecao-blossom" exact>
          <Blossom />
        </Route>
        <Route path="/colecao-tropical" exact>
          <Tropical />
        </Route>
        <Route path="/colecao-into-the-woods" exact>
          <IntoTheWoods />
        </Route>
        <Route path="/colecao-birds" exact>
          <Birds />
        </Route>
        <Route path="/colecao-sea" exact>
          <Sea />
        </Route>
        <Route path="/colecao-nature" exact>
          <Nature />
        </Route>
        <Route path="/colecao-espiritual" exact>
          <Espiritual />
        </Route>
        <Route path="/colecao-rosas" exact>
          <Rosas />
        </Route>
        <Route path="/colecao-borboletas" exact>
          <Borboletas />
        </Route>
        <Route path="/colecao-dalias" exact>
          <Dalias />
        </Route>
        <Route path="/colecao-alcachofras" exact>
          <Alcachofras />
        </Route>
        <Route path="/colecao-lacados" exact>
          <Lacados />
        </Route>
        <Route path="/colecao-rusticos" exact>
          <Rusticos />
        </Route>
        <Route path="/colecao-basicos" exact>
          <Basicos />
        </Route>
        <Route path="/produtos/co/:colSlug">
          <Collection />
        </Route>
        <Route path="/produtos/:id">
          <Produto />
        </Route>
        <Route path="/indice">
          <Indice />
        </Route>
        <Route path="/">
          <Home />
          <History />
          <Indice />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
